<template>
  <div class="justify-content-center d-flex">
    <div class="mt-5 d-flex flex-column flex-wrap">
      <form>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">New Password</span>
          </div>
          <input
            type="password"
            class="form-control"
            aria-label="New Password"
            aria-describedby="basic-addon1"
            v-model="newPassword"
            @blur="updatePasswordRequirements"
          />
        </div>

        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Confirm New Password</span>
          </div>
          <input
            type="password"
            class="form-control input-fix"
            aria-label="Confirm New Password"
            aria-describedby="basic-addon1"
            v-model="newPasswordConfirm"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="savePassword"
            >
              Set Password
            </button>
          </div>
        </div>
      </form>
    </div>

    <div
      class="mt-5 d-flex flex-column"
      style="margin-left: 100px; background-color: white"
    >
      <div class="card-body">
        <h3>Password Requirements</h3>
        <div class="d-flex flex-column">
          <span :class="reqsMinChars ? 'text-success' : 'text-danger'"
            >Minimum 8 characters</span
          >
          <span :class="reqsUpperCase ? 'text-success' : 'text-danger'"
            >Uppercase letter</span
          >
          <span :class="reqsLowerCase ? 'text-success' : 'text-danger'"
            >Lowercase letter</span
          >
          <span :class="reqsDigits ? 'text-success' : 'text-danger'"
            >At least 1 digit</span
          >
          <span :class="reqsSymbols ? 'text-success' : 'text-danger'"
            >At least 1 symbol</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const api = require("../api");
import Swal from "sweetalert2";
let passwordValidator = require("password-validator");
let schema = new passwordValidator()
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

export default {
  components: {},
  data() {
    return {
      token: "",
      newPassword: "",
      newPasswordConfirm: "",
      reqsMinChars: false, // False = not validated
      reqsLowerCase: false,
      reqsUpperCase: false,
      reqsDigits: false,
      reqsSymbols: false,
    };
  },
  methods: {
    updatePasswordRequirements() {
      let passwordReqs = schema.validate(this.newPassword, { list: true });
      
      this.reqsMinChars = passwordReqs.includes("min") ? false : true;
      this.reqsLowerCase = passwordReqs.includes("lowercase") ? false : true;
      this.reqsUpperCase = passwordReqs.includes("uppercase") ? false : true;
      this.reqsDigits = passwordReqs.includes("digits") ? false : true;
      this.reqsSymbols = passwordReqs.includes("symbols") ? false : true;
    },
    savePassword() {
      if (!schema.validate(this.newPassword)) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Password requirements not met!",
        });
        return; // Ghetto?
      }

      if (this.newPassword !== this.newPasswordConfirm) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Passwords must match!",
        });
        return;
      }

      let changes = [
        {
          key: "password",
          value: this.newPassword,
          oldValue: null,
        },
      ];

      Swal.showLoading();
      console.log('calling');
      if (this.token){
        api.setToken(this.token);
      }
      api
        .updateResource(
          this.userId,
          "user",
          "guardgg",
          changes,
          this.token,
        )
        .then((res) => {
          console.log(res);

          Swal.hideLoading();

          if (res.status == 200 && res.data.successful.length > 0) {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Password setup successful!",
            });
            this.$router.push("/account");
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Something went wrong! Please try again.",
            });
          }
        }).catch((err) => {
          console.error(err);

          Swal.hideLoading();
        });
    },
  },
  computed: {
    userId() {
      if (this.token) {
        let data = JSON.parse(atob(this.token.split('.')[1]));
        return data.id;
      }

      return this.$store.getters["auth/user"].id;
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }

    // If no token or user, and the user has not set the pw
    if((!this.token && !this.$store.getters["auth/user"])) {
      this.$router.push("/"); // Redirect to homepage if they have a password set already
    }

    if (this.$store.getters["auth/user"] && this.$store.getters["auth/user"].lastPwChange) {
      this.$router.push("/"); // Redirect to homepage if they have a password set already
    }
  },
  watch: {
    newPassword() {
      this.updatePasswordRequirements();
    },
  },
};
</script>

<style lang="scss">
.input-fix {
  border-radius: 0 !important;
}
</style>
